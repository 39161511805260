/* Template Name: Techwind - Multipurpose Tailwind CSS Landing Page Template
   Author: Shreethemes
   Email: support@shreethemes.in
   Website: https://shreethemes.in
   Version: 1.7.0
   Created: May 2022
   File Description: Main Icon file of the template
*/

/* Plugins */
@import "icons/materialdesignicons";
